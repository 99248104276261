import { Locale, Walk, WalkLength } from '../types';
import type { LinkProps } from 'next/link';

export const IS_SERVER = typeof window === 'undefined';

function walksPathname(locale: Locale) {
	return locale === 'en' ? 'walks' : 'promenader';
}

function walkLengthPathname(length: WalkLength, locale: Locale) {
	if (length === 'short') {
		return locale === 'en' ? 'short' : 'kort';
	}

	if (length === 'long') {
		return locale === 'en' ? 'long' : 'lang';
	}

	return length;
}

export function walksLinkProps(locale: Locale): LinkProps {
	const pathname = walksPathname(locale);
	return {
		href: `/walks`,
		as: `/${pathname}`,
		locale,
	};
}

export function walkLinkProps(walk: Walk): LinkProps {
	const pathname = walksPathname(walk.locale);
	return {
		href: {
			pathname: `/walks/[slug]`,
			query: { slug: walk.slug },
		},
		as: `/${pathname}/${walk.slug}`,
		locale: walk.locale,
	};
}

export function walkLengthLinkProps(walk: Walk, length: WalkLength): LinkProps {
	const pathname = walksPathname(walk.locale);
	const lengthPathname = walkLengthPathname(length, walk.locale);
	return {
		href: {
			pathname: `/walks/[slug]/[length]`,
			query: { slug: walk.slug, length: length },
		},
		as: `/${pathname}/${walk.slug}/${lengthPathname}`,
		locale: walk.locale,
	};
}

const getUrlFromUrlObject = (
	url: LinkProps['as'] | LinkProps['href'],
): string => {
	if (typeof url === 'string') {
		return url;
	}

	if (typeof url === 'object' && url.pathname) {
		return url.pathname;
	}

	return '';
};

const getUrlFromProps = (props: LinkProps) => {
	return getUrlFromUrlObject(props.as || props.href);
};

export function absoluteWalksUrl(locale: Locale): string {
	const linkProps = walksLinkProps(locale);
	return absoluteUrl(getUrlFromProps(linkProps), locale);
}

export function absoluteWalkUrl(walk: Walk): string {
	const linkProps = walkLinkProps(walk);
	return absoluteUrl(getUrlFromProps(linkProps), walk.locale);
}

export function absoluteWalkLengthUrl(walk: Walk, length: WalkLength): string {
	const linkProps = walkLengthLinkProps(walk, length);
	return absoluteUrl(getUrlFromProps(linkProps), walk.locale);
}

export function absoluteUrl(url: string, locale: Locale): string {
	const localePathname =
		locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? '' : `/${locale}`;
	return `${process.env.NEXT_PUBLIC_APP_URL}${localePathname}${url}`;
}
