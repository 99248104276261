import Link, { LinkProps } from 'next/link';
import { FC, MouseEventHandler, ReactNode } from 'react';
import styles from './style.module.css';
import clsx from 'clsx';

type BaseProps = {
	children: ReactNode;
	className?: string;
	onClick?: MouseEventHandler;
};

type LinkMainButtonProps = BaseProps & {
	element: 'a';
} & LinkProps;

type ButtonMainButtonProps = BaseProps & {
	element?: 'button';
	onClick?: MouseEventHandler;
	disabled?: boolean;
};

type MainButtonProps = LinkMainButtonProps | ButtonMainButtonProps;

const MainButton: FC<MainButtonProps> = (props) => {
	if (props.element === 'a') {
		return <LinkMainButton {...props} />;
	}

	return <ButtonMainButton {...props} />;
};

export default MainButton;

const LinkMainButton: FC<LinkMainButtonProps> = ({
	children,
	className,
	onClick,
	...props
}) => {
	return (
		<Link
			{...props}
			className={clsx(styles.button, className)}
			draggable={false}
			onClick={onClick}
			onTouchStart={() => {}}
		>
			{children}
		</Link>
	);
};

const ButtonMainButton: FC<ButtonMainButtonProps> = ({
	children,
	className,
	onClick,
	disabled,
}) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={clsx(styles.button, className)}
			disabled={disabled}
			onTouchStart={() => {}}
		>
			{children}
		</button>
	);
};
