import { FC } from 'react';
import { useRouter } from 'next/router';
import { Trans, t } from '@lingui/macro';
import { useGameState } from '../../functions/game-state';
import Banner from '../Banner';

type GameStateBannerProps = {};

const GameStateBanner: FC<GameStateBannerProps> = ({}) => {
	const router = useRouter();
	const gameState = useGameState();

	if (
		(gameState.walkId === null && gameState.startTime === null) ||
		router.pathname === '/walks/[slug]/[length]'
	) {
		return null;
	}

	return (
		<Banner
			actions={[
				{
					title: t`Fortsätt`,
					onClick: () => {
						if (!gameState.walkLink) return;
						router.push(gameState.walkLink.href, gameState.walkLink.as);
					},
				},
				{
					title: t`Avsluta`,
					onClick: () => {
						gameState.clearState();
					},
				},
			]}
		>
			<Trans>En promenad pågår</Trans>
		</Banner>
	);
};

export default GameStateBanner;
