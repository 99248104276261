import {
	FC,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Walk, Answer, Question, CompleteWalk, WalkLength } from '../types';
import { useRouter } from 'next/router';
import { getSubscription } from '../functions/serviceworker/notification';
import { walkLengthLinkProps } from '../utilites/linkProps';
import { LinkProps } from 'next/link';
import { schedulePushNotification } from './api';
import getDuration from './time/duration';
import { getWalkLengthForNumberOfQuestions } from './walk-length';

type Answers = Record<Question['id'], Answer['id']>;

type GameState = {
	walkId: Walk['id'] | null;
	walkLink: LinkProps | null;
	startTime: number | null;
	answers: Answers;
	setAnswer: (question: Question['id'], answer: Answer['id']) => void;
	questions: Question[];
	setWalkState: (walk: CompleteWalk, length: WalkLength) => void;
	clearPersistedState: () => void;
	clearState: () => void;
};

export const GameStateContext = createContext<GameState>({
	walkId: null,
	walkLink: null,
	startTime: null,
	answers: {},
	setAnswer: () => {},
	questions: [],
	setWalkState: () => {},
	clearPersistedState: () => {},
	clearState: () => {},
});

export function GameStateProvider({ children }: { children: React.ReactNode }) {
	const [walkId, setWalkId] = useState<CompleteWalk['id'] | null>(null);
	const [walkLink, setWalkLink] = useState<LinkProps | null>(null);
	const [startTime, setStartTime] = useState<number | null>(null);
	const [answers, setAnswers] = useState<Answers>({});
	const [questions, setQuestions] = useState<Question[]>([]);

	usePersistInLocalStorage('walkId', walkId, setWalkId);
	usePersistInLocalStorage('walkLink', walkLink, setWalkLink);
	usePersistInLocalStorage('startTime', startTime, setStartTime);
	usePersistInLocalStorage('answers', answers, setAnswers);
	usePersistInLocalStorage('questions', questions, setQuestions);

	const clearPersistedState = useCallback(() => {
		localStorage.removeItem('walkId');
		localStorage.removeItem('startTime');
		localStorage.removeItem('answers');
		localStorage.removeItem('questions');
	}, []);

	const clearState = useCallback(async () => {
		clearPersistedState();
		setWalkId(null);
		setWalkLink(null);
		setStartTime(null);
		setAnswers({});
		setQuestions([]);

		// Remove any pending subscriptions
		const subscription = await getSubscription();
		if (subscription) {
			schedulePushNotification(subscription, 0);
		}
	}, [clearPersistedState]);

	const setWalkState = useCallback(
		(walk: CompleteWalk, length: WalkLength) => {
			if (walkId !== walk.id) {
				setWalkId(walk.id);
				setWalkLink(walkLengthLinkProps(walk, length));
				setStartTime(Date.now());
				setAnswers({});
				setQuestions(walk.questions);
			}
		},
		[walkId],
	);

	const setAnswer = useCallback(
		(question: Question['id'], answer: Answer['id']) => {
			setAnswers((answers) => ({
				...answers,
				[question]: answer,
			}));
		},
		[],
	);

	return (
		<GameStateContext.Provider
			value={{
				walkId,
				walkLink,
				startTime,
				answers,
				setAnswer,
				questions,
				setWalkState,
				clearPersistedState,
				clearState,
			}}
		>
			{children}
		</GameStateContext.Provider>
	);
}

export const GameStateResetter: FC = () => {
	const router = useRouter();
	const { walkId, answers, questions, startTime, clearState } = useGameState();

	useEffect(() => {
		// Clear if a walk is completed
		if (
			walkId !== null &&
			router.route !== '/walks/[slug]/[length]' &&
			Object.keys(answers).length === questions.length
		) {
			clearState();
		}

		// Clear if the walk has ended more than 1 hour ago
		if (
			walkId !== null &&
			startTime !== null &&
			router.route !== '/walks/[slug]/[length]'
		) {
			const timeSinceStart = Date.now() - startTime;
			const expectedTimeToFinish = getDuration(
				getWalkLengthForNumberOfQuestions(questions.length),
			);

			const timeout = 60 * 60 * 1000;

			if (timeSinceStart > expectedTimeToFinish + timeout) {
				clearState();
			}
		}
	}, [router.route, walkId, answers, questions, startTime, clearState]);

	return null;
};

export function useGameState() {
	return useContext(GameStateContext);
}

function usePersistInLocalStorage<T>(
	key: string,
	value: T,
	setValue: (value: T) => void,
) {
	/**
	 * Load value from session storage
	 */
	useEffect(() => {
		const storedValue = localStorage.getItem(key);
		if (storedValue !== null) {
			setValue(JSON.parse(storedValue));
		}
	}, [key, setValue]);

	/**
	 * Save value to session storage
	 *
	 * We need to keep a ref to prevent this from happening the first time the
	 * useEffect hook runs. This overwrites any state in localStorage with
	 * the default null values otherwise.
	 */
	const preventSetInitial = useRef(false);
	useEffect(() => {
		if (!preventSetInitial.current) {
			preventSetInitial.current = true;
			return;
		}

		localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);
}
