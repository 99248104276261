import { Locale } from '../types';

// Duplicate of next.config.js
const locales = ['sv', 'en'];
const defaultLocale = 'sv';

export default function getLocale(maybeLocale?: string): Locale {
	return isLocale(maybeLocale) ? maybeLocale : defaultLocale;
}

export function isLocale(maybeLocale: unknown): maybeLocale is Locale {
	return typeof maybeLocale === 'string' && locales.includes(maybeLocale);
}
