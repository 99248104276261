import { WalkLength, Locale } from '../types';
import {
	ApiResponseWalk,
	ApiResponseWalks,
	ApiResponseCategories,
	ApiResponsePostResult,
	ApiRequestPostResult,
	ApiResponseResult,
	ApiResponseQuestion,
	ApiResponseGetInfoContent,
} from '../types/response';
import {
	isApiResponseWalk,
	isApiResponseWalks,
	isApiResponseCategories,
	isApiResponsePostResult,
	isApiResponseResult,
	isApiResponseQuestion,
	isApiResponseGetInfoContent,
	isWalk,
	isApiResponseGetVapidKey,
} from './typeguards';

const base = process.env.API_URL || '/api/backend';

async function get(url: `/${string}`, locale?: Locale): Promise<unknown> {
	let joining = '?';
	if (url.includes('?')) {
		joining = '&';
	}

	let localeParam = '';
	if (locale) {
		localeParam = `locale=${locale}`;
	}

	const res = await fetch(`${base}${url}${joining}${localeParam}`);

	return await res.json();
}

async function post(url: `/${string}`, body: unknown): Promise<unknown> {
	const res = await fetch(`${base}${url}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

	return await res.json();
}

export async function getWalks(locale: Locale): Promise<ApiResponseWalks> {
	const walks = await get('/walks', locale);

	if (!isApiResponseWalks(walks)) {
		throw new Error('Invalid response');
	}

	return walks;
}

export async function getWalk(
	slug: string,
	locale?: Locale,
	length?: WalkLength,
): Promise<ApiResponseWalk> {
	const walk = await get(`/walks/${slug}`, locale);

	if (!isApiResponseWalk(walk)) {
		throw new Error('Invalid response');
	}

	return walk;
}

export async function getWalksInLocales(locales: Locale[]) {
	// Get walks in all locales
	const walksResponses = await Promise.all(
		Array.isArray(locales) ? locales.map((locale) => getWalks(locale)) : [],
	);

	// Make a flat array of all the walks
	const walks = walksResponses.flatMap((walksResponse) =>
		Array.isArray(walksResponse)
			? walksResponse.filter((walk) => isWalk(walk))
			: [],
	);

	return walks;
}

export async function getCategories(
	locale: Locale,
): Promise<ApiResponseCategories> {
	const categories = await get('/categories', locale);

	if (!isApiResponseCategories(categories)) {
		throw new Error('Invalid response');
	}

	return categories;
}
export async function getQuestion(id: number): Promise<ApiResponseQuestion> {
	const walk = await get(`/questions/${id}`);

	if (!isApiResponseQuestion(walk)) {
		throw new Error('Invalid response');
	}

	return walk;
}

export async function getResult(locale: Locale): Promise<ApiResponseResult> {
	const result = await get('/result', locale);

	if (!isApiResponseResult(result)) {
		throw new Error('Invalid response');
	}

	return result;
}

export async function postResult({
	walkId,
	questions,
	correctAnswers,
	distance,
}: ApiRequestPostResult): Promise<ApiResponsePostResult> {
	const response = await post('/result', {
		walkId,
		questions,
		correctAnswers,
		distance,
	});

	if (!isApiResponsePostResult(response)) {
		throw new Error('Invalid response');
	}

	return response;
}

export async function getInfoContent(
	locale: Locale,
): Promise<ApiResponseGetInfoContent> {
	const infoContent = await get('/info', locale);

	if (!isApiResponseGetInfoContent(infoContent)) {
		throw new Error('Invalid response');
	}

	return infoContent;
}

export async function getVapidPublicKey(): Promise<string> {
	const res = await get('/get-vapid-public-key');

	if (!isApiResponseGetVapidKey(res)) {
		throw new Error('Invalid response');
	}

	if (!('key' in res) || typeof res.key !== 'string') {
		throw new Error('Invalid response');
	}

	return res.key;
}

export async function schedulePushNotification(
	subscription: PushSubscription,
	delay: number,
): Promise<boolean> {
	const res = await post('/schedule-push', {
		endpoint: subscription.endpoint,
		delay,
	});

	return true;
}
