import {
	Image,
	Walk,
	CompleteWalk,
	Question,
	Answer,
	Category,
	WalkLength,
	Result,
} from '../types';
import {
	ApiResponseWalk,
	ApiResponseWalks,
	ApiResponseCategories,
	ApiResponseQuestion,
	ApiResponsePostResult,
	ApiResponseResult,
	ApiResponseGetInfoContent,
	ApiResponseGetVapidKey,
	ApiError,
} from '../types/response';

export function isImage(image: unknown): image is Image {
	return (
		typeof image === 'object' &&
		image !== null &&
		'id' in image &&
		'url' in image &&
		'width' in image &&
		'height' in image &&
		'alt' in image
	);
}

export function isWalk(walk: unknown): walk is Walk {
	return (
		typeof walk === 'object' &&
		walk !== null &&
		'id' in walk &&
		'slug' in walk &&
		'title' in walk &&
		'shape' in walk &&
		'locale' in walk
	);
}

export function isCompleteWalk(walk: unknown): walk is CompleteWalk {
	return (
		isWalk(walk) &&
		'questions' in walk &&
		Array.isArray((walk as CompleteWalk).questions) &&
		(walk as CompleteWalk).questions.every(isQuestion)
	);
}

export function isCategory(category: unknown): category is Category {
	return (
		typeof category === 'object' &&
		category !== null &&
		'id' in category &&
		'heading' in category &&
		'walks' in category &&
		'size' in category
	);
}

export function isQuestion(question: unknown): question is Question {
	return (
		typeof question === 'object' &&
		question !== null &&
		'id' in question &&
		'text' in question &&
		'answers' in question &&
		Array.isArray((question as Question).answers) &&
		(question as Question).answers.every(isAnswer)
	);
}

export function isAnswer(answer: unknown): answer is Answer {
	return (
		typeof answer === 'object' &&
		answer !== null &&
		'id' in answer &&
		'text' in answer
	);
}

export function isWalkLength(length: string): length is WalkLength {
	return length === 'short' || length === 'long';
}

export function isResult(result: unknown): result is Result {
	return (
		typeof result === 'object' &&
		result !== null &&
		'sumQuestions' in result &&
		'popularWalks' in result &&
		'highlighted' in result
	);
}

export function isApiResponseWalks(
	response: unknown,
): response is ApiResponseWalks {
	return (
		typeof response === 'object' &&
		response !== null &&
		(Array.isArray(response) ? response.every(isWalk) : isApiError(response))
	);
}

export function isApiResponseWalk(
	response: unknown,
): response is ApiResponseWalk {
	return (
		typeof response === 'object' &&
		response !== null &&
		(isWalk(response) || isApiError(response))
	);
}

export function isApiResponseQuestion(
	response: unknown,
): response is ApiResponseQuestion {
	return (
		typeof response === 'object' &&
		response !== null &&
		(isQuestion(response) || isApiError(response))
	);
}

export function isApiResponseCategories(
	response: unknown,
): response is ApiResponseCategories {
	return (
		typeof response === 'object' &&
		response !== null &&
		(Array.isArray(response)
			? response.every(isCategory)
			: isApiError(response))
	);
}

export function isApiResponsePostResult(
	response: unknown,
): response is ApiResponsePostResult {
	return (
		typeof response === 'object' &&
		response !== null &&
		'created' in response &&
		response.created === true
	);
}

export function isApiResponseResult(
	response: unknown,
): response is ApiResponseResult {
	return (
		typeof response === 'object' && response !== null && isResult(response)
	);
}

export function isApiResponseGetInfoContent(
	response: unknown,
): response is ApiResponseGetInfoContent {
	return (
		typeof response === 'object' &&
		response !== null &&
		'content' in response &&
		typeof response.content === 'string'
	);
}

export function isApiResponseGetVapidKey(
	response: unknown,
): response is ApiResponseGetVapidKey {
	return (
		typeof response === 'object' &&
		response !== null &&
		'key' in response &&
		typeof response.key === 'string'
	);
}

export function isApiError(response: unknown): response is ApiError {
	return (
		typeof response === 'object' &&
		response !== null &&
		'code' in response &&
		'message' in response &&
		'data' in response &&
		typeof response.data === 'object' &&
		response.data !== null &&
		'status' in response.data
	);
}
