import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

export default function useServiceWorker(): ServiceWorker | null {
	const { locale } = useRouter();
	const serviceWorker = useRef<ServiceWorker | null>(null);
	useEffect(() => {
		(async () => {
			if (!('serviceWorker' in navigator)) {
				return null;
			}

			const registration = await navigator.serviceWorker.register('/sw.js');

			serviceWorker.current =
				registration.installing || registration.waiting || registration.active;
		})();
	}, []);

	useEffect(() => {
		if (serviceWorker.current) {
			serviceWorker.current.postMessage({ locale });
		}
	}, [serviceWorker.current, locale]); // eslint-disable-line react-hooks/exhaustive-deps

	return serviceWorker.current;
}
