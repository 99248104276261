import { WalkLength } from '../../types';

const getDuration = (length: WalkLength) => {
	const demoMode =
		process.env.NODE_ENV !== 'test' &&
		process.env.NEXT_PUBLIC_DEMO_MODE === 'true';

	if (demoMode) {
		return 30 * 1000;
	}

	return (length === 'long' ? 40 : 20) * 60 * 1000;
};

export default getDuration;
