import { useEffect, useRef } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Head from 'next/head';
import useServiceWorker from '../functions/serviceworker/useServiceWorker';
import Script from 'next/script';
import title from '../utilites/title';
import { Locale, i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import getLocale from '../utilites/locale';

import 'normalize.css';
import '../style/main.css';
import { GameStateProvider, GameStateResetter } from '../functions/game-state';
import GameStateBanner from '../components/GameStateBanner';

function MyApp({ Component, pageProps }: AppProps) {
	useServiceWorker();

	const router = useRouter();
	const locale = getLocale(router.locale);
	const firstRender = useRef(true);

	if (firstRender.current) {
		loadLocaleSync(locale);
		firstRender.current = false;
	}

	useEffect(() => {
		loadLocaleAsync(locale);
	}, [locale]);

	return (
		<I18nProvider i18n={i18n}>
			{process.env.NEXT_PUBLIC_GA_ID && (
				<>
					<Script
						src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
						strategy="afterInteractive"
					/>
					<Script id="google-analytics" strategy="afterInteractive">
						{`
							window.dataLayer = window.dataLayer || [];
							function gtag(){window.dataLayer.push(arguments);}
							gtag('js', new Date());

							gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}');
						`}
					</Script>
				</>
			)}
			<Head>
				<title>{title()}</title>
				<meta name="theme-color" content="#f7cb44" />
				<link rel="manifest" href="/app.webmanifest" />
				<link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
				<link rel="icon" href="/icons/favicon.png" />
			</Head>
			<GameStateProvider>
				<GameStateResetter />
				<GameStateBanner />
				{/* @ts-ignore TODO Fix type issue and remove ts-ignore */}
				<Component {...pageProps} />
			</GameStateProvider>
		</I18nProvider>
	);
}

export default MyApp;

function loadLocaleSync(locale: Locale) {
	const { messages } = require(`../locale/${locale}/messages`);
	i18n.load(locale, messages);
	i18n.activate(locale);
}

async function loadLocaleAsync(locale: Locale) {
	const { messages } = await import(`../locale/${locale}/messages`);
	i18n.load(locale, messages);
	i18n.activate(locale);
}
