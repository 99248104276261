import { WalkLength } from '../types';

export const getNumberOfQuestionsForWalkLength = (
	length: WalkLength,
): number => {
	return length === 'short' ? 5 : 10;
};

export const getWalkLengthForNumberOfQuestions = (
	questions: number,
): WalkLength => {
	return questions <= 5 ? 'short' : 'long';
};
