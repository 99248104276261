import { FC, ReactNode } from 'react';
import MainButton from '../MainButton';
import styles from './style.module.css';

type BannerProps = {
	children: ReactNode;
	actions?: { title: string; onClick: () => void }[];
};

const Banner: FC<BannerProps> = ({ children, actions }) => {
	return (
		<div className={styles.banner}>
			<p>{children}</p>
			{actions && actions.length > 0 && (
				<div className={styles.buttons}>
					{actions.map((action) => (
						<MainButton
							key={action.title}
							onClick={action.onClick}
							className={styles.button}
						>
							{action.title}
						</MainButton>
					))}
				</div>
			)}
		</div>
	);
};

export default Banner;
